import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Select this option by clicking the tab "By selection". Move the map to
the place where you want to add activities. In the list on the left side
below the filter you see the activities of this map section. Click on the
checkbox for an activity to include it in your collection.`}</p>
    <Image src="pro/hand-pick-activities.jpg" mdxType="Image" />
    <p>{`To filter and search for specific content you specify this in the
control box above the activity list.`}</p>
    <br />
    <Image className="w-2/5 mx-auto" src="pro/handpick-filter.jpg" mdxType="Image" />
    <br />
    <p>{`Here you can include “Hidden” activities that are normally not visible in
public Trailguide. You upload hidden activities if you don’t want them to be
visible in public Trailguide. For example for a temporary event.`}</p>
    <br />
    <p>{`To add a point of interest (POI) make a long left click on the map. Select
an icon and add a label text for it in the text box. Click, hold and drag the
POI to move it to another location.`}</p>
    <Image src="pro/poi-editor.jpg" mdxType="Image" />
    <p>{`When you are done. Click “Save". Then the collection is saved and two unique
URLs and QR-codes are generated. You find them by selecting the `}<b>{`QR`}</b>{` tab
above the map.`}</p>
    <br />
    <Image className="mx-auto w-3/4" src="pro/qr-codes.jpg" mdxType="Image" />
    <br />
    <br />
    <Link className="float-left" to="/pro/map" mdxType="Link">
  ← Create a custom map
    </Link>
    <Link className="float-right" to="/pro/mapbysearch" mdxType="Link">
  Select activities by search →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      